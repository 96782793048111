<template>
  <Dialog
    v-model:visible="itemDialog"
    :style="{ width: '100vw'}"
    :maximizable="false"
    :closable="false"
    position="top"
    :modal="true"
    :showHeader="false"
    class="p-fluid  p-m-0 p-dialog-maximized"
  >
    <div class="p-d-flex p-jc-between">
      <div class="p-mb-2 p-mr-2">
        <i class="pi pi-plus-circle"></i>
        Item Details
        <p>Note : After changing press enter key</p>
      </div>
      <div class="p-mb-2 p-mr-2 pull-right">
        <Button
          class="p-button-success"
          icon="pi pi-check-circle"
          label="Next"
          @click="emitItemDetail()"
        />
      </div>
    </div>
     <div style="height: 0.2em; background-color: #eee;" class="p-p-0">
      <ProgressBar
        v-if="progressBar"
        mode="indeterminate"
        style="height: 0.2em"
      />
    </div>

    <div  v-if="conditionsList.length > 0">
      <div class="p-md-6 pull-left p-p-0" >
        <template v-for="items in ConditionsComputed" :key="items">
          <div class="condition-style" v-if="items.sectionName == 'section_1'" >
            <div class="p-formgroup-inline">
                <div class="p-field">
                    <input class="item-chk-box p-mr-1" v-model="items.checkedItem" type="checkbox" />
                    <b>{{items.itemSpName}}</b>
                </div>
            </div>
              <div class="p-formgroup-inline">
                <div class="p-field ">
                    <Button @click="counter(items,'inc')" icon="pi pi-plus" class="p-button-success p-p-1"/>
                </div>
                <div class="p-field ">
                    <InputNumber  v-model="items.quantity"  class="num-box-style" />
                </div>
                <div class="p-field ">
                    <Button @click="counter(items,'dec')" icon="pi pi-minus" class="p-button-danger p-p-1"/>
                </div>
                  <div class="p-field ">
                    <InputNumber  v-model="items.condittionPrice" mode="currency" currency="USD" locale="en-US" class="num-box-style" />
                </div>
                <div class="p-field p-mt-2">
                  =
                </div>
                <div class="p-field ">
                    <InputNumber class="num-box-style" :value="items.totalAmount"  mode="currency" currency="USD" locale="en-US" readonly/>
                </div>
              </div>
          </div>
        </template>
      </div>
      <div class="p-md-6 pull-left p-p-0" >
        <template v-for="items in ConditionsComputed" :key="items">
          <div class="condition-style" v-if="items.sectionName == 'section_2'" >
            <div class="p-formgroup-inline">
                <div class="p-field">
                    <input class="item-chk-box p-mr-1" v-model="items.checkedItem" type="checkbox" />
                    <b>{{items.itemSpName}}</b>
                </div>
            </div>
              <div class="p-formgroup-inline">
                <div class="p-field ">
                    <Button @click="counter(items,'inc')" icon="pi pi-plus" class="p-button-success p-p-1"/>
                </div>
                <div class="p-field ">
                    <InputNumber  :value="items.quantity" class="num-box-style" readonly/>
                </div>
                <div class="p-field ">
                    <Button @click="counter(items,'dec')" icon="pi pi-minus" class="p-button-danger p-p-1"/>
                </div>
                  <div class="p-field ">
                    <InputNumber  v-model="items.condittionPrice" mode="currency" currency="USD" locale="en-US" class="num-box-style" />
                </div>
                <div class="p-field p-mt-2">
                  =
                </div>
                <div class="p-field ">
                    <InputNumber class="num-box-style" :value="items.totalAmount"  mode="currency" currency="USD" locale="en-US" readonly/>
                </div>
              </div>
          </div>
        </template>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";

interface ModifiedConditionType {
    conditionStatus: string;
    condittionPrice: number;
    itemId: string;
    itemSpId: string;
    itemSpName: string;
    parentItemId: string;
    sectionName: string;
    quantity: number;
    totalAmount: number;
    checkedItem: boolean;
}

@Options({
  props: {
    receiptDetail: Object
  },
  watch: {
    receiptDetail(obj) {
      this.itemDialog   = obj.dialogStatus;
      this.conditionsList   = obj.itemLists;
    },
  },
  emits: ["emitItemDetailEvent"],
})




export default class ItemConditions extends Vue {
  private toast;
  private itemDialog = false;
  private itemId = "";
  private scheduleId = "";
  private hasCondition = "";
  private checkTxn;
  private conditionsList: ModifiedConditionType [] = [];

  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  get progressBar() {
   return this.store.getters.getProgressBar;
  }

  get ConditionsComputed()
  {

    this.conditionsList.forEach(e => {
      e.condittionPrice = Number(e.condittionPrice);
      e.totalAmount = Number(e.quantity * e.condittionPrice);
    });

    return this.conditionsList;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  emitItemDetail()
  {
    this.$emit('emitItemDetailEvent',this.conditionsList);
    this.itemDialog = false;
  }

  counter(item,action)
  {
    if(action == 'inc')
    {
      item.quantity++;
    }
    else
    {
      if(item.quantity <= 0)
      {
        this.toast.showWarning('Cannot decrease the quatity less then 0');
      }
      else
      {
        item.quantity--;
      }
    }
  }

}
</script>

<style scoped>

.condition-style{
    font-size: 16px;
    border-bottom: 1px dotted #ccc;
}

.item-chk-box {
    width: 30px;
    height: 30px;
    padding: 0;
    vertical-align: center;
    position: relative;
    top: 8px;
}

.num-box-style
{
  width:90px !important;
}

</style>
